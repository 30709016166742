import React from 'react';
import styled from 'styled-components';

import BottomSheet from 'components/bottom-sheet';

const DiscountContainer = styled.div`
  min-height: 310px;
  padding: 28px;
  box-sizing: border-box;
`;
const DiscountWrapper = styled.div`
  border-bottom: 1px solid #3b3516;
`;
const DiscountItem = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

const DiscountName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #3b3516;
  width: 268px;
`;

const DiscountAmount = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #3b3516;
`;
const DiscountNameBlock = styled.div`
  margin-bottom: 10px;
  margin-left: 15px;
`;

const DiscountCard = ({
  discountList,
  isShow,
  setIsShow,
  fromDetail = false,
  promotionInfo,
}) => {
  return (
    <BottomSheet isShow={isShow} setIsShow={setIsShow} title="折扣">
      <DiscountContainer>
        {fromDetail ? (
          <>
            {promotionInfo?.orderPromotion && (
              <DiscountWrapper>
                <DiscountItem>
                  <DiscountName>
                    {promotionInfo.orderPromotion?.blockDoc}
                  </DiscountName>
                  <DiscountAmount>
                    ${promotionInfo.orderPromotion?.discountAmount}
                  </DiscountAmount>
                </DiscountItem>
                <DiscountNameBlock>
                  {promotionInfo.orderPromotion?.promotionName?.map((name) => {
                    return <DiscountName>{name}</DiscountName>;
                  })}
                </DiscountNameBlock>
              </DiscountWrapper>
            )}
            {promotionInfo?.shippingFeePromotion && (
              <DiscountWrapper>
                <DiscountItem>
                  <DiscountName>
                    {promotionInfo.shippingFeePromotion?.blockDoc}
                  </DiscountName>
                  <DiscountAmount>
                    ${promotionInfo.shippingFeePromotion?.discountAmount}
                  </DiscountAmount>
                </DiscountItem>
                <DiscountNameBlock>
                  {promotionInfo.shippingFeePromotion?.promotionName?.map(
                    (name) => {
                      return <DiscountName>{name}</DiscountName>;
                    }
                  )}
                </DiscountNameBlock>
              </DiscountWrapper>
            )}
          </>
        ) : (
          <>
            {discountList?.map((discount, index) => (
              <DiscountItem key={index}>
                <DiscountName>{discount.title}</DiscountName>
                <DiscountAmount>-{discount.price}</DiscountAmount>
              </DiscountItem>
            ))}
          </>
        )}
      </DiscountContainer>
    </BottomSheet>
  );
};

export default DiscountCard;
