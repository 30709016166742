import index from 'axios-middleware';
import React from 'react';
import { useState } from 'react';

import BottomSheet from '../bottom-sheet';
import { useAPI } from '../../utils/api';
import { showLoading, dismissLoading } from '../../utils/loading';
import { useEffect } from 'react';

const OrderCanacel = ({
  show,
  order_no,
  cancel_option,
  goBack,
  setOrderCanacel = () => {},
}) => {
  const api = useAPI();

  const [selectedValue, setSelectValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [cancelReasonValue, setCancelReasonValue] = useState('');

  const cancel = () => {
    if (selectedValue === '' || selectedValue === undefined) {
      alert('請選擇取消原因');
      return;
    }
    if (inputValue === '' && selectedValue === '其他') {
      alert('請輸入取消原因');
      return;
    }
    showLoading();
    setOrderCanacel();

    api
      .orderCancle(order_no, cancelReasonValue)
      .then((res) => {
        if (res.code == 400) {
          dismissLoading();
          alert(res.reason, () => {
            window.location.reload();
          });
          return;
        } else if (res.code != 200) {
          dismissLoading();
          return;
        } else {
          dismissLoading();
          window.popup('訂單已取消');
          goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        dismissLoading();
      });
  };
  useEffect(() => {
    setCancelReasonValue(selectedValue);
    if (selectedValue === '其他') {
      setCancelReasonValue(inputValue.trim());
    }
  }, [selectedValue, inputValue]);

  return (
    <>
      <BottomSheet isShow={show} setIsShow={show} orderCancel={setOrderCanacel}>
        <div className="content ">
          <p
            style={{ fontSize: '18px', textAlign: 'left', fontWeight: 'bold' }}
          >
            取消原因
          </p>
          <div style={{ marginTop: '10px' }}>
            {cancel_option.map((item, index) => (
              <div
                key={`div-receive-${index}`}
                style={{ marginBottom: '15px' }}
              >
                <input
                  id={`receive-${index}`}
                  value={item.name}
                  type="radio"
                  checked={item.name === selectedValue}
                  onChange={(e) => {
                    setSelectValue(e.target.value);
                    if (selectedValue != '其他') {
                      setInputValue('');
                    }
                  }}
                />
                <label
                  htmlFor={`receive-${index}`}
                  style={{ marginLeft: '8px' }}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>
          {selectedValue === '其他' && (
            <textarea
              placeholder="可輸入50個中文字"
              maxLength={50}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value.trim());
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.target.blur();
                }
              }}
            ></textarea>
          )}
        </div>
        <div className="div-cancel z-100 ">
          <button onClick={cancel}>確定取消</button>
        </div>
      </BottomSheet>

      <style jsx>{`
        .content {
          overflow-y: scroll;
          background: #fff;
          box-sizing: border-box;
          padding: 0px 16px 180px;
          margin-bottom: 360px;
        }
        .div-cancel {
          position: absolute;
          box-sizing: border-box;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 20px 16px;
          background: #fff;
          filter: drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.11));

          &.z-100 {
            z-index: 100;
            transform: translate3d(0, 0, 100);
            -webkit-transform: translate3d(0, 0, 100);
          }
        }
        textarea {
          box-sizing: border-box;
          min-height: 60px;
          width: 100%;
        }
      `}</style>
    </>
  );
};

export default OrderCanacel;
